<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h1>{{this.plural}}</h1>
          <v-btn
            v-if="this.isAllowed('user', 'c')"
            class="mx-2"
            fab
            small
            dark
            color="indigo"
            @click="create"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn class="mx-2" dark color="info" to="/usertypes">User Types</v-btn>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                  v-model="options.search"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
              <v-btn color="info" fab x-small><v-icon>mdi-magnify</v-icon></v-btn>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="data"
                :options.sync="options"
                :server-items-length="pagination.dataCount"
                :loading="pagination.loading"
                :items-per-page="-1"
                class="elevation-1"
                @click:row="rowClick"
            >
              <template v-slot:item.cb="{ item }">
                {{formatPrice(item.cb)}}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import { mapGetters } from "vuex"
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: 'ID', align: 'start', sortable: true, value:'id'},
          { text: 'Name', align: 'start', sortable: true, value:'name'},
          { text: 'Username', sortable: true, value: 'username' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', sortable: true, value: 'email' },
          { text: 'User Type', value: 'UserType.name' },
          { text: 'CB', value: 'cb', sortable: false },
          { text: 'Building', value: 'metadata.printNode', sortable: false },
          { text: 'Online', value: 'isLoggedIn', sortable: false }
        ],

        pagination: {
          loading: false,
          dataCount: 0,
        },
        options: {
          search: "",
          sortBy: ['name'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15
        },
        updatingRoute: false,
        init: false,

        editMode: false,
        data: [],
        singular: "User",
        singularLower: "user",
        plural: "Users",
        pluralLower: "users"
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "name"]
        this.options.sortDesc = [oldQuery.order || true]
        this.options.search = oldQuery.search || ""
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    async mounted(){
      this.init = true;
      await this.getAllData();
      this.init = false;
    },
    computed: {
      ...mapGetters(['getUser', 'getId', 'getToken', 'isAllowed', 'getEndpoint'])
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "name"]
          this.options.sortDesc = [oldQuery.order || true]
          this.options.search = oldQuery.search || ""
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      //used for pagination
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        return oldQuery;
      },
      //used for pagination end
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      create(){
        this.$router.push({ path: `/${this.pluralLower}/create`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      formatPrice(value){
        if(value===null) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      async getAllData(){
        try {
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            search: this.options.search
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error

          res.data.data.forEach(user => {
            user.name = (user.firstName?user.firstName:'')+' '+(user.lastName?user.lastName:'');

            if(user.dateOfBirth){
              user.dateOfBirth = new Date(user.dateOfBirth).toISOString().substring(0, 10);
            }
            if(user.hireDate){
              user.hireDate = new Date(user.hireDate).toISOString().substring(0, 10);
            }
            if(user.fireDate){
              user.fireDate = new Date(user.fireDate).toISOString().substring(0, 10);
            }

            if(user?.csrUser.length>0){
              user.cb = user.csrUser.reduce((a,b)=>a+(b.status>0?b.metadata?.csrPaidAt?0:parseFloat(b.metadata?.grandTotal*(b.metadata?.commissionRate/100)):0),0)
            }else{
              user.cb = 0
            }
          });

          this.data = res.data.data

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              await this.$router.replace({path: `/${this.pluralLower}`, query: newQuery});
            }
            else{
              await this.$router.push({path: `/${this.pluralLower}`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      }
    }
  }
</script>
